import React from 'react';
import App from './App/App';

const Content = () => {

  return (
    <div className="app">
      <App />
    </div>
  );
}

export default Content;
